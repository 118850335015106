import { graphql, useStaticQuery } from "gatsby";

const useCourseCatalogHeroData = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCourseCatalogHero(filter: { node_locale: { eq: "en-US" } }) {
        nodes {
          heroImage {
            fluid {
              srcSet
            }
          }
          heroHeader
          heroSubheader
          cardHeader
          cardSubheader
          popularCourses {
            title
            courseDuration
            shortDescription {
              shortDescription
            }
            courseType
            courseCost
            slug
          }
        }
      }
    }
  `);
  return data.allContentfulCourseCatalogHero.nodes[0];
};

export default useCourseCatalogHeroData;
