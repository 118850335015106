import React, { useState } from "react";
import styled from "@emotion/styled";
import useNewCourseCatalogGridData from "../hooks/new-course-catalog-cards-data";
import { useDispatch } from "react-redux";
import FilterColumn from "../components/FilterColumn/FilterColumn";
import CourseGrid from "../components/CourseGrid/CourseGrid";
import { SET_GRID_COURSES, SET_SEARCH_TERM } from "../state/constants/course";
import { theme } from "../utils/global-styles/theme";
import Layout from "../components/Layout/Layout";
import SearchBar from "../components/FilterColumn/SearchBar";
import { Global, css } from "@emotion/core";
import CourseCatalogHero from "../components/CourseCatalog/CourseCatalogHero";

const CourseCatalogPage = () => {
  const DesktopAndTabletWrapper = styled.div`
      font-family: ${theme.fontFaces.paragraphs};
      display: block;
      margin: 0 auto;
      min-height: 600px;
      margin-bottom: 50px;
      margin-top: 61px;
      max-width: ${theme.breakpoints.maxContent};
      @media only screen and (max-width: ${theme.breakpoints.helper750}) {
        display: none;
      }

      @media (min-width: ${theme.breakpoints.helper750}) and (max-width: ${theme
        .breakpoints.desktopUp}) {
            margin-top: 50px;
    }
    `,
    MobileWrapper = styled.div`
      display: block;
      margin-bottom: 50px;

      @media (min-width: ${theme.breakpoints
          .helper750}) and (max-width: 5000px) {
        display: none;
      }
    `,
    FlexContainer = styled.div`
      display: flex;
      flex-direction: row;
    `,
    FilterColumnContainer = styled.div`
      width: 30%;
      display: block;
      border-right: 2px solid #b4b4b4;
      position: relative;
      min-height: 600px;
    `,
    CourseGridContainer = styled.div`
      width: 80%;
      display: block;

      height: 100vw;
      overflow-y: scroll;
      position: relative;
    `,
    LineSeparator = styled.hr`
      margin-top: 27px;
      margin-bottom: 27px;
      display: block;
      width: 80%;
      float: right;
      margin-right: 20px;
      border-top: 1px solid #b4b4b4;

      @media only screen and (max-width: ${theme.breakpoints.helper750}) {
        float: none;
        margin: 0 auto;
        margin-top: 30px;
      }
    `,
    OpenFiltersButton = styled.div`
      cursor: pointer;
      width: 80%;
      text-align: center;
      padding-top: 7px;
      padding-bottom: 7px;
      display: block;
      margin: 0 auto;
      font-family: ${theme.fontFaces.paragraphs};
      margin-top: 30px;
    `,
    CloseFiltersButton = styled.div`
      position: fixed;
      bottom: 0;
      width: 100%;
      text-align: center;
      padding-top: 7px;
      padding-bottom: 7px;
      color: white;
      display: block;
      margin: 0 auto;
      font-family: ${theme.fontFaces.paragraphs};
      margin-top: 30px;
    `,
    MobileFilterColumnWrapper = styled.div`
      display: block;
      width: 100vw;
      position: fixed;
      left: 0;
      background-color: #ffffff;
      box-shadow: 0 0 4px 2px rgb(0 0 0 / 17%);
      z-index: 6;
      right: 0;
      font-family: ${theme.fontFaces.paragraphs};
      color: #323232;
      top: 60px;
      overflow-y: scroll;
      bottom: 0;
    `;

  const courses = useNewCourseCatalogGridData();
  const dispatch = useDispatch();
  const coursesWithDuplicatesRemoved = courses.filter(
    (value, index, self) =>
      index === self.findIndex(t => t.courseId === value.courseId)
  );

  dispatch({ type: SET_GRID_COURSES, payload: coursesWithDuplicatesRemoved });

  const [openMobileFilters, setOpenMobileFilters] = useState(false);

  const toggleFilterModal = () => {
    setOpenMobileFilters(!openMobileFilters);
  };

  return (
    <Layout fullDeviceWidth>
      <CourseCatalogHero />
      {openMobileFilters && (
        <Global
          styles={css`
            body {
              position: fixed;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
            }
          `}
        />
      )}
      <DesktopAndTabletWrapper fullDeviceWidth>
        <FlexContainer>
          <FilterColumnContainer>
            <SearchBar />
            <LineSeparator />
            <FilterColumn setOpenMobileFilters={setOpenMobileFilters} />
          </FilterColumnContainer>

          <CourseGridContainer>
            <CourseGrid />
          </CourseGridContainer>
        </FlexContainer>
      </DesktopAndTabletWrapper>
      <MobileWrapper>
        <SearchBar />
        <OpenFiltersButton
          className="primary-bg"
          onClick={() => toggleFilterModal()}
        >
          Filters
        </OpenFiltersButton>
        <LineSeparator />
        <CourseGrid />
        {openMobileFilters && (
          <MobileFilterColumnWrapper>
            <FilterColumn setOpenMobileFilters={setOpenMobileFilters} />
            <CloseFiltersButton
              className="primary-bg"
              onClick={() => {
                setOpenMobileFilters(false);
                dispatch({ type: SET_SEARCH_TERM, payload: "" });
              }}
            >
              Search
            </CloseFiltersButton>
          </MobileFilterColumnWrapper>
        )}
      </MobileWrapper>
    </Layout>
  );
};

export default CourseCatalogPage;
