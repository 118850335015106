import { graphql, useStaticQuery } from "gatsby";

const useCourseSubjectData = () => {
  const data = useStaticQuery(graphql`
        query   {
            allContentfulCourseSubject (
                filter: {
                  title: { ne: null }
                }
              ){
              nodes {
                info
                title
              }
            }
          }
  `);
  return data.allContentfulCourseSubject.nodes;
};

export default useCourseSubjectData;
