import { graphql, useStaticQuery } from "gatsby";

const useNewCourseCatalogGridData = () => {
  const data = useStaticQuery(graphql`
    query {
        allContentfulCourseDetailPage(
        filter: {
            showOnCourseCataloguePage: { eq: true }
        }
        ) {
        totalCount
        nodes {
            languageCode
            courseId
            courseImage {
            fluid {
                srcSet
            }
            description
            }
            slug
            courseType
            title
            shortDescription {
                shortDescription
            }
            courseCost
            courseCostInOtherCurrencies {
              usd
              pound
              euro
            }
            courseDuration
            courseDescription {
                courseDescription
            }
            darkImageOverlayText
            courseStartDate
            subject {
                title
                info
              }
              courseDurationRange
              newTheme {
                title
                info
              }
              showBackgroundImage
              showOnCourseCataloguePage
              aboutTheInstructors {
                name
              }
        }
        }
    }
  `);
  return data.allContentfulCourseDetailPage.nodes;
};

export default useNewCourseCatalogGridData;
