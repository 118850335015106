import React from "react";
import Img from "gatsby-image";
import styled from "@emotion/styled";
import { theme } from "../../utils/global-styles/theme";
import useCourseCatalogHeroData from "../../hooks/course-catalog-hero-data";
import { Link } from "gatsby";

const CourseCatalogHero = props => {
  const {
      heroImage,
      heroHeader,
      heroSubheader,
      cardHeader,
      cardSubheader,
      popularCourses,
    } = useCourseCatalogHeroData(),
    HeroContainer = styled.section`
      display: flex;
      flex-direction: column;
    `,
    BackgroundImageSection = styled.div`
      display: grid;
      grid-template: 2em auto 2em / 3% 1fr 40%;
      height: 400px;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        height: 100%;
      }

      @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
          .breakpoints.desktopUp}) {
        grid-template: 2em 1fr 2em / 3% 1fr 3em;
      }
    `,
    BackgroundImage = styled(Img)`
      grid-area: 1 / 1 / span 3 / span 3;
      height: 100%;
      width: 100%;
    `,
    HeroData = styled.div`
      grid-area: 2 / 2 / 2 / 2;
      z-index: 3;
      align-self: center;
    `,
    HeroHeader = styled.h1`
  
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {

        padding-top: 0.3em;
      }
    `,
    HeroSubtitle = styled.p`
      font-weight: bold;
      font-size: 1.8rem;
      font-family: ${theme.fontFaces.headers};
      letter-spacing: -0.9px;
      line-height: 2.5rem;
    `,
    PopularCoursesRow = styled.div`
      display: grid;
      grid-template-columns: repeat(3, 33.33%);
      min-height: 350px;
      @media only screen and (max-width: ${theme.breakpoints.helper750}) {
        display: block;
      }

      @media (min-width: ${theme.breakpoints.helper750}) and (max-width: ${theme
          .breakpoints.desktopUp}) {
        height: 350px;
      }
    `,
    PopularCoursesTitleCard = styled.div`
      padding: 8%;
      color: ${theme.colors.main};
      background-color: ${theme.colors.royalBlue};
      h2 {
        padding-bottom: 20px;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper750}) {
        &.hide-on-mobile {
          display: none;
        }
      }
      @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
          .breakpoints.desktopUp}) {
      }
    `,
    PopularCoursesCourseCard = styled(Link)`
      display: grid;
      padding-bottom: 3%;
      grid-template: 30px 3fr 1fr 1fr / 50px 1fr 50px;
      &:hover {
        p {
          display: block;
        }
        h2 {
          display: none;
        }
      }
      @media only screen and (max-width: ${theme.breakpoints.helper750}) {
        color: ${theme.colors.main};
        &.hide-on-mobile {
          display: none;
        }
      }

      @media (min-width: ${theme.breakpoints.helper750}) and (max-width: ${theme
          .breakpoints.desktopUp}) {
        grid-template: 1em 75% 1em / 1em 1fr 1em;
      }
    `,
    CourseCardTitle = styled.h2`
      grid-area: 2 / 2 / 2 / 2;
      font-size: 34px;
      @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
          .breakpoints.desktopUp}) {
        font-size: 30px;
        line-height: 40px;
      }
    `,
    CourseCardDescription = styled.p`
      grid-area: 2 / 2 / 2 / 2;
      font-size: 1.8rem;
      line-height: 1.3em;
      display: none;
      @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
          .breakpoints.desktopUp}) {
        font-size: 1.4em;
      }
    `,
    CourseCardDetails = styled.div`
      grid-area: 3 / 2 / 3 / 2;
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-self: end;
      p {
        margin-right: 30px;
        font-weight: bold;
        font-family: ${theme.fontFaces.headers};
        border-top: 3px;
        border-top-style: solid;
        padding-top: 0.3em;
      }

      @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
          .breakpoints.desktopUp}) {
        p {
          margin-right: 10px;
        }
      }
    `,
    EnrolButtonContainer = styled.div`
      grid-area: 4 / 2 / 4 / 2;
      display: flex;
      align-self: center;
      justify-content: flex-end;
      margin-right: 5px;
      a {
        border-bottom: 4px solid;
        cursor: pointer;
        color: ${theme.colors.black};
      }
    `,
    MobileCourseSection = styled.div`
      display: none;
      @media only screen and (max-width: ${theme.breakpoints.helper750}) {
        display: block;
        padding-top: 30px;

        div {
          position: relative;
          margin-bottom: 40px;
          margin-left: 12px;
          top: 5px;
          font-size: 32px;
          font-weight: bold;
          font-family: ${theme.fontFaces.headers};
        }
      }
    `;
  return (
    <HeroContainer>
      <BackgroundImageSection>
        <BackgroundImage fluid={heroImage.fluid} />
        <HeroData>
          <HeroHeader className="codedH1 white">{heroHeader}</HeroHeader>
          <HeroSubtitle className="white">{heroSubheader}</HeroSubtitle>
        </HeroData>
      </BackgroundImageSection>
      <MobileCourseSection>
        <div>{cardHeader}</div>
      </MobileCourseSection>
      <PopularCoursesRow>
        <PopularCoursesTitleCard className="hide-on-mobile">
          <h2>{cardHeader}</h2>
          <p>{cardSubheader}</p>
        </PopularCoursesTitleCard>
        <PopularCoursesCourseCard
          to={`/course/${popularCourses[0].slug}`}
          className="course-catalog-hero-primary-card"
        >
          <CourseCardTitle>{popularCourses[0].title}</CourseCardTitle>
          <CourseCardDescription>
            {popularCourses[0].shortDescription.shortDescription}
          </CourseCardDescription>
          <CourseCardDetails>
            <p>{popularCourses[0].courseDuration}</p>
            <p>{popularCourses[0].courseType === "Master Class" ? "On-Demand" : popularCourses[0].courseType}</p>
            <p>{popularCourses[0].courseCost}</p>
          </CourseCardDetails>
          <EnrolButtonContainer>
            <Link to={`/course/${popularCourses[0].slug}`}>
              Enroll Now
            </Link>
          </EnrolButtonContainer>
        </PopularCoursesCourseCard>
        <PopularCoursesCourseCard
          to={`/course/${popularCourses[1].slug}`}
          className="hide-on-mobile course-catalog-hero-secondary-card"
        >
          <CourseCardTitle>{popularCourses[1].title}</CourseCardTitle>
          <CourseCardDescription>
            {popularCourses[1].shortDescription.shortDescription}
          </CourseCardDescription>
          <CourseCardDetails>
            <p>{popularCourses[1].courseDuration}</p>
            <p>{popularCourses[1].courseType}</p>
            <p>{popularCourses[1].courseCost}</p>
          </CourseCardDetails>
          <EnrolButtonContainer>
            <Link to={`/course/${popularCourses[1].slug}`} >
              Enroll Now
            </Link>
          </EnrolButtonContainer>
        </PopularCoursesCourseCard>
      </PopularCoursesRow>
    </HeroContainer>
  );
};

export default CourseCatalogHero;
