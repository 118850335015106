import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import MagnifyingGlass from "./MagnifyingGlass";
import Close from "./Close";
import { theme } from "../../utils/global-styles/theme";
import { SET_SEARCH_TERM } from "../../state/constants/course";
import { useDispatch } from "react-redux";
import { useQueryParam } from "gatsby-query-params";

const SearchBar = () => {
  const Wrapper = styled.div`
      width: 80%;
      float: right;
      margin-right: 20px;

      @media only screen and (max-width: ${theme.breakpoints.helper750}) {
        float: none;
        padding-top: 30px;
        margin: 0 auto;
      }
    `,
    Container = styled.div`
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      border: 2px solid #b4b4b4;
      align-items: baseline;
      padding: 5px;
    `,
    StyledSearchInput = styled("input")`
     background-color: ${theme.colors.background};
      border: none;
      width: 70%;
      font-size: 15px;
      font-family: ${theme.fontFaces.paragraphs};
      font-weight: bold;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: ${theme.colors.black};
      }
    `,
    SearchIconWrapper = styled.div`
      margin-right: 5%;
    `,
    CloseIconWrapper = styled.div`
      position: absolute;
      right: 35px;
      cursor: pointer;

      @media only screen and (max-width: ${theme.breakpoints.helper750}) {
        // mobile only
        right: 13%;
      }
    `;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: SET_SEARCH_TERM, payload: "" });
  });

  const searchParam = useQueryParam("search", "");

  useEffect(() => {
    if (searchParam !== "") {
      if (searchParam?.includes("_")) {
        const newString = searchParam.replace(/_/g, " ");
        dispatch({ type: SET_SEARCH_TERM, payload: newString });
        setValue("search", newString);
      } else {
        dispatch({ type: SET_SEARCH_TERM, payload: searchParam });
        setValue("search", searchParam);
      }
    }
  }, [searchParam]);

  const { register, reset, setValue } = useForm();

  return (
    <Wrapper>
      <Container>
        <SearchIconWrapper>
          <MagnifyingGlass primary={theme.colors.black} />
        </SearchIconWrapper>
        <StyledSearchInput
          id="search-catalog"
          className={"black"}
          type="search"
          name="search"
          {...register("search", {
            onChange: e => {
              dispatch({ type: SET_SEARCH_TERM, payload: e.target.value });
            },
          })}
          type="text"
          placeholder={"Search"}
        />
        <CloseIconWrapper
          onClick={() => {
            reset({ search: "" });
            dispatch({ type: SET_SEARCH_TERM, payload: "" });
          }}
        >
          <Close primary={theme.colors.black} />
        </CloseIconWrapper>
      </Container>
    </Wrapper>
  );
};

export default SearchBar;
