import { graphql, useStaticQuery } from "gatsby";

const useCourseThemeData = () => {
  const data = useStaticQuery(graphql`
        query {
            allContentfulCourseTheme (
                filter: {
                  title: { ne: null }
                }
              ) {
              nodes {
                info
                title
              }
            }
          }
  `);
  return data.allContentfulCourseTheme.nodes;
};

export default useCourseThemeData;
